import React, { useState } from "react";
import {Navbar, Container, Offcanvas, Row,Col, Tab, ListGroup} from 'react-bootstrap'
import { AiOutlineMenu } from 'react-icons/ai';
import {Link} from "react-router-dom";
import { Avatar, Timeline, Collapse, Button } from 'antd';
import { AntDesignOutlined, ClockCircleOutlined  } from '@ant-design/icons';
import {  BsInstagram, BsLinkedin, BsEmail } from 'react-icons/bs';
import { AiOutlineMail} from 'react-icons/ai';

const avatar = require("../assets/img/IMG_0490.JPG");


const { Panel } = Collapse;

function callback(key) {
  console.log(key);
}

const iconsx = {
  float: 'right'
}

const icondx = {
 float: 'left'
}

const text = {
  textAlign: 'justify'
}




const AboutMe = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  
 
 
    
  
    return (
        <div className="App">
           <Navbar>
        <Container fluid>
            <Navbar.Brand >Lorenzo Negri</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
            <span variant="primary" onClick={handleShow}>
            <AiOutlineMenu />

            </span>

            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menù</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                  <Row>
                    <Col>
                      <ListGroup  variant="flush" >
                        <ListGroup.Item >
                        <Link to={"/"}>Home</Link> 
                        </ListGroup.Item>
                        <ListGroup.Item >
                        <Link to={"/AboutMe"}>About Me</Link> 
                        </ListGroup.Item>
                        <ListGroup.Item >
                        <Link to={"/Portfolio"}>Portfolio</Link> 
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Tab.Container>
                </Offcanvas.Body>
            </Offcanvas>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        <Container >
          <Row className="mx-auto pt-5">
            <Col>
            <Avatar
              className="avatar"
              shape="square"
              size={{ xs: 200, sm: 200, md: 200, lg: 200, xl: 300, xxl: 200 }}
              src={avatar}
            />
            </Col>
         

          </Row>
          <Row className="mx-auto pt-3">
            <Col style={text}>
            Ho frequentato il corso di Fashion Styling & Communication allo IED di Milano e sono Owner di Fairmag, 
            un magazine digitale. All'interno di questo magazine metto in pratica le skills apprese con l'obiettivo di
             comunicare le mie due più grandi passioni: Moda e Musica. Mi considero un comunicatore che cerca di essere
              creativo e innovativo guidato da una grande ambizione. Nel corso dei tre anni ho sviluppato progetti di 
              diverso ambito legati alla Comunicazione, Storytelling, Digital pr, Art Direction, Organizzazione di Eventi,
               Fotografia e altro.
            </Col>
          </Row>
          <hr className="mt-5 mb-5"></hr>
          
      </Container>


      <Container>
          <Row className="pb-3">
            <Col>
            <Button  type="dashed" size="large">
                   <a href= "https://www.instagram.com/lorenzonegriii/" target='blank'><b><span className="me-3"><BsInstagram /></span>Instagram</b></a>
                  </Button>    
            </Col>
            <Col>
            <Button  type="dashed" size="large">
                   <a href= "https://www.linkedin.com/in/lorenzo-negri-a67599209/" target='blank'><b><span className="me-3"><BsLinkedin /></span>Linkedin</b></a>
                  </Button>    
            </Col>
            <Col className="pt-3 pt-md-0">
            <Button  type="dashed" size="large">
                   <a href= "mailto:lorenegri7@gmail.com" target='blank'><b><span className="me-3"><AiOutlineMail /></span>Email</b></a>
                  </Button>  
            </Col>
          </Row>
        </Container>
        

        
            
      </div>
    );
  };
  
  export default AboutMe;