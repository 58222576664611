import React, { useState } from "react";
import {Navbar, Container, Offcanvas, Row,Col, Tab, ListGroup} from 'react-bootstrap'
import { AiOutlineMenu } from 'react-icons/ai';
import {Link} from "react-router-dom";
import { BsDownload} from 'react-icons/bs';
import { AiOutlineEye} from 'react-icons/ai';
import Slide from "react-awesome-reveal";
import { Card, Avatar } from 'antd';
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import pdfreco from '../assets/pdf/Recombinant.pdf';

import pdfKAPPA from '../assets/pdf/KAPPA.pdf';
import pdfFree from '../assets/pdf/freeofform.pdf';
import pdfClose from '../assets/pdf/close.pdf';
import pdfArt from '../assets/pdf/art.pdf';
import pdfFranklin from '../assets/pdf/franklin.pdf';
import pdfAlaia from '../assets/pdf/alaia.pdf';
import pdfComfort from '../assets/pdf/comfort.pdf';
import pdfFuturism from '../assets/pdf/futurism.pdf';
import pdfMTV from '../assets/pdf/mtv.pdf';
import pdfMoncler from '../assets/pdf/moncler.pdf';
import pdfMSGM from '../assets/pdf/msgm.pdf';
import pdfTecniche from '../assets/pdf/tecniche.pdf';
import pdfDust from '../assets/pdf/dust.pdf';
import pdfZoom from '../assets/pdf/zoom.pdf';


const foto_1 = require("../assets/img/slide-1.jpg");
const recombinant = require("../assets/img/recombinant.png");
const fairmag = require("../assets/img/fairmag.png");


const art = require("../assets/img/art.png");
const free = require("../assets/img/free.png");
const kappa = require("../assets/img/kappa.png");
const close = require("../assets/img/close.png");
const franklin = require("../assets/img/franklin.png");
const alaia = require("../assets/img/alaia.png");
const comfort = require("../assets/img/comfort.png");
const futurism = require("../assets/img/futurismo.png");
const mtv = require("../assets/img/mtv.png");
const moncler = require("../assets/img/moncler.png");
const msgm = require("../assets/img/msgm.png");
const tecniche = require("../assets/img/tecniche.png");
const dust = require("../assets/img/dust.png");
const zoom = require("../assets/img/zoom.png");


const { Meta } = Card;







const Portfolio = () => {
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

 
    
  
    return (
        <div className="App">
        <Navbar>
        <Container fluid>
            <Navbar.Brand >Lorenzo Negri</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
            <span variant="primary" onClick={handleShow}>
            <AiOutlineMenu />

            </span>

            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menù</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                  <Row>
                    <Col>
                      <ListGroup  variant="flush" >
                        <ListGroup.Item >
                        <Link to={"/"}>Home</Link> 
                        </ListGroup.Item>
                        <ListGroup.Item >
                        <Link to={"/AboutMe"}>About Me</Link> 
                        </ListGroup.Item>
                        <ListGroup.Item >
                        <Link to={"/Portfolio"}>Portfolio</Link> 
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Tab.Container>
                </Offcanvas.Body>
            </Offcanvas>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        <Container  >
            <Slide>
            <Row className="pt-5 ">
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={recombinant}
                />
                }
                actions={[
                    <a href={pdfreco} target="_blank" rel="noreferrer" download={pdfreco}> <BsDownload /></a>,
                    <a href={pdfreco} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Progetto Sociologia"
                description="Combattere i problemi ambientali con l’espressione artistica "
                />
                </Card>
                </Col>
                
                
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={kappa}
                />
                }
                actions={[
                    <a href={pdfKAPPA} target="_blank" rel="noreferrer" download={pdfKAPPA}> <BsDownload /></a>,
                    <a href={pdfKAPPA} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="KAPPA"
                description="Storytelling & Creative direction X KAPPA campaign"
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={free}
                />
                }
                actions={[
                    <a href={pdfFree} target="_blank" rel="noreferrer" download={pdfFree}> <BsDownload /></a>,
                    <a href={pdfFree} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="FREE OF FORM"
                description="Creative direction X arti contemporanee "
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={close}
                />
                }
                actions={[
                    <a href={pdfClose} target="_blank" rel="noreferrer" download={pdfClose}> <BsDownload /></a>,
                    <a href={pdfClose} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Close to the Sky"
                description="Performance art project  "
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={art}
                />
                }
                actions={[
                    <a href={pdfArt} target="_blank" rel="noreferrer" download={pdfArt}> <BsDownload /></a>,
                    <a href={pdfArt} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Art History cover"
                description="Artbook che racconta le cover piu iconiche della storia della musica "
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={msgm}
                />
                }
                actions={[
                    <a href={pdfMSGM} target="_blank" rel="noreferrer" download={pdfMSGM}> <BsDownload /></a>,
                    <a href={pdfMSGM} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Fashion Film "
                description="Fashion Film with MSGM"
                />
                </Card>
                </Col>
                
                
                
               
                
               
            </Row>
            </Slide>
            <Slide >
            <Row className="pt-5 ">
            <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={comfort}
                />
                }
                actions={[
                    <a href={pdfComfort} target="_blank" rel="noreferrer" download={pdfComfort}> <BsDownload /></a>,
                    <a href={pdfComfort} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Art Direction"
                description="Art Direction X brand emergente COMFORT.lab"
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={franklin}
                />
                }
                actions={[
                    <a href={pdfFranklin} target="_blank" rel="noreferrer" download={pdfFranklin}> <BsDownload /></a>,
                    <a href={pdfFranklin} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Franklin Jones"
                description="Direzione creativa per metodologia della progettazione"
                />
                </Card>
                </Col>
                
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={alaia}
                />
                }
                actions={[
                    <a href={pdfAlaia} target="_blank" rel="noreferrer" download={pdfAlaia}> <BsDownload /></a>,
                    <a href={pdfAlaia} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Event design"
                description="Event design project X ALAIA"
                />
                </Card>
                </Col>
               
                
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={futurism}
                />
                }
                actions={[
                    <a href={pdfFuturism} target="_blank" rel="noreferrer" download={pdfFuturism}> <BsDownload /></a>,
                    <a href={pdfFuturism} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="FUTURISMO NOSTALGICO"
                description="Semiotica dell’arte "
                />
                </Card>
                </Col>
              
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={mtv}
                />
                }
                actions={[
                    <a href={pdfMTV} target="_blank" rel="noreferrer" download={pdfMTV}> <BsDownload /></a>,
                    <a href={pdfMTV} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="MTV"
                description="Progetto teoria dell’audiovisivo "
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={moncler}
                />
                }
                actions={[
                    <a href={pdfMoncler} target="_blank" rel="noreferrer" download={pdfMoncler}> <BsDownload /></a>,
                    <a href={pdfMoncler} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Storybook"
                description="Storybook del brand"
                />
                </Card>
                </Col>
                
                
                
                
               
                
               
            </Row>
            <Row className="pt-5 ">
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={tecniche}
                />
                }
                actions={[
                    <a href={pdfTecniche} target="_blank" rel="noreferrer" download={pdfTecniche}> <BsDownload /></a>,
                    <a href={pdfTecniche} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Tecniche Fotografiche"
                description="Progetto di Fotografia"
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={dust}
                />
                }
                actions={[
                    <a href={pdfDust} target="_blank" rel="noreferrer" download={pdfDust}> <BsDownload /></a>,
                    <a href={pdfDust} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="Storybook"
                description="Storybook X Teoria della percezione e psicologia della forma "
                />
                </Card>
                </Col>
                <Col md={4} sm={12} className="pb-5" >
                <Card
              
                cover={
                <img
                    alt="example"
                    src={zoom}
                />
                }
                actions={[
                    <a href={pdfZoom} target="_blank" rel="noreferrer" download={pdfZoom}> <BsDownload /></a>,
                    <a href={pdfZoom} target="_blank" rel="noreferrer" > <AiOutlineEye size={20}/></a>,
                ,
                ]}
            >
                <Meta
               
                title="ZOOMIN"
                description="Direzione artistica di un Magazine "
                />
                </Card>
                </Col>
            
                
                
                
               
                
               
            </Row>
            </Slide>
           
           
        </Container>
      </div>
    );
  };
  
  export default Portfolio;