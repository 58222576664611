import React, { useState } from "react";
import {Navbar, Container, Offcanvas, Row,Col, Tab, ListGroup} from 'react-bootstrap'
import { AiOutlineMenu } from 'react-icons/ai';
import {Radio,  Image,Timeline,Card, Button} from 'antd';
import { Slide, Zoom} from "react-awesome-reveal";
import { ClockCircleOutlined } from '@ant-design/icons';
import { BsDownload, BsTextCenter, BsInstagram } from 'react-icons/bs';
import { GrLinkedin } from 'react-icons/gr';
import {Link} from "react-router-dom";
import Portfolio from "./portfolio";
import AboutMe from "./aboutme";


import SimpleImageSlider from "react-simple-image-slider";

import pdfreco from '../assets/pdf/Recombinant.pdf';
import pdfKAPPA from '../assets/pdf/KAPPA.pdf';
import pdfFree from '../assets/pdf/freeofform.pdf';
import pdfClose from '../assets/pdf/close.pdf';
import pdfArt from '../assets/pdf/art.pdf';
import pdfFranklin from '../assets/pdf/franklin.pdf';


import Carousel from 'react-bootstrap/Carousel';


const foto_5 = require("../assets/img/tupac.webp");
const foto_6 = require("../assets/img/sorrenti1.webp");
const foto_7 = require("../assets/img/sorrenti2.webp");
const foto_8 = require("../assets/img/virgil.webp");

const copertina = require("../assets/img/fairmag.webp");

const copertina3 = require("../assets/img/image00011.webp");
const copertina4 = require("../assets/img/image00002.webp");

const copertina12 = require("../assets/img/copertina4.webp");
const mobile_1 = require("../assets/img/001181320017.webp");
const mobile_2 = require("../assets/img/001181320005.webp");
const mobile_3 = require("../assets/img/001181320014.jpeg");
const about = require("../assets/img/about.webp");

const art = require("../assets/img/art.png");
const free = require("../assets/img/free.png");
const kappa = require("../assets/img/kappa.png");
const close = require("../assets/img/close.png");
const franklin = require("../assets/img/franklin.png");


const recombinant = require("../assets/img/recombinant.png");



const contentStyle = {
    
    color: '#fff',
    lineHeight: '180px',
    textAlign: 'center',
    background: '#364d79',
    height: '100vh',
    objectfit: 'cover',
  
  };

  const pdfbutton = {
    color: 'black',
    width: '20vh'

    
  }

  const pdfa = {
    textDecoration: 'none',
    color: 'inherit'
  }

  const divdesc ={
    position: 'relative',
  }

  const footer_text = {
    color: 'white',
    fontSize: '2vh'

  }

 const icon = {
   fontSize: '8vh',
   color: 'black'
 }

 const iconsx = {
   float: 'right'
 }

 const icondx = {
  float: 'left'
}


const images = [
  { url: copertina4 },
  { url: copertina12 },
  { url: copertina3 },

];
  
const images_mobile = [
  { url: mobile_1 },
  { url: mobile_2 },
  { url: mobile_3 },

];
  



  const { Meta } = Card;


const Home = () => {
    const [showPage, setShowPage] = useState("home");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [dotPosition, setDotPosition] = React.useState('top');

    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    
    const handlePositionChange = ({ target: { value } }) => {
      setDotPosition(value);
    };
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);

    }
 
    
  
    return (
        <div className="App">
                <Navbar>
        <Container fluid>
            <Navbar.Brand >Lorenzo Negri</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
            <span variant="primary" onClick={handleShow}>
            <AiOutlineMenu />

            </span>

            <Offcanvas show={show} onHide={handleClose} placement={'end'}>
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>Menù</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                  <Row>
                    <Col>
                      <ListGroup  variant="flush" >
                        <ListGroup.Item >
                        <Link to={"/"}>Home</Link> 
                        </ListGroup.Item>
                        <ListGroup.Item >
                        <Link to={"/AboutMe"}>About Me</Link> 
                        </ListGroup.Item>
                        <ListGroup.Item >
                        <Link to={"/Portfolio"}>Portfolio</Link> 
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Tab.Container>
                </Offcanvas.Body>
            </Offcanvas>
            </Navbar.Collapse>
        </Container>
        </Navbar>
    
       
      
        <Container fluid>
          <Row>
          <Carousel className="slider_web" activeIndex={index} onSelect={handleSelect} >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={copertina4}
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={copertina3}
          alt="Second slide"
        />

       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={copertina12}
          alt="Third slide"
        />

        
      </Carousel.Item>
    </Carousel>
          </Row>
        </Container>
        <Container fluid className="container_mobile">
          <Row>
          <Carousel className="slider_mobile" activeIndex={index} onSelect={handleSelect} >
      <Carousel.Item>
        <img
          className="d-block w-100 slider_mobile_img"
          src={mobile_1}
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 slider_mobile_img"
          src={mobile_3}
          alt="Second slide"
        />

       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100 slider_mobile_img"
          src={mobile_2}
          alt="Third slide"
        />

        
      </Carousel.Item>
    </Carousel>
          </Row>
        </Container>

        <Container className="primoSect">
        <Slide Cascade  >
            <Row>  
                <Col md={4} sm={12}>
              
                <Card title="" bordered={false} >
                    
                <p><img fluid  width="100%" height="100%"  src={recombinant}/></p>
                
                
                <h4>Progetto Sociologia</h4>
                <p>Combattere i problemi ambientali con l’espressione artistica</p>
                <Button className="pdfbutton" style={pdfbutton} type="dashed">
                  <BsDownload />
                 <span className="ms-1"><a style={pdfa} href={pdfreco} target="_blank" rel="noreferrer"> Download</a></span>   
                  </Button>
          
                </Card>
               
                </Col>

                <Col md={4} sm={12}>
              
                <Card title="" bordered={false} >
                
                <p><img fluid  width="100%" height="100%"  src={kappa}/></p>
                <h4>KAPPA</h4>
                <p>Storytelling & Creative direction X KAPPA campaign </p>
                <Button style={pdfbutton} type="dashed">
                  <BsDownload />
                 <span className="ms-1"><a style={pdfa} href={pdfKAPPA} target="_blank" rel="noreferrer"> Download</a></span>   
                  </Button>
             
                </Card>
               
                </Col>
                <Col md={4} sm={12}>
              
                <Card title="" bordered={false} >
             
                <p><img fluid  width="100%" height="100%"  src={free}/></p>
             
                <h4>FREE OF FORM</h4>
                <p>Creative direction X arti contemporanee </p>
                <Button style={pdfbutton} type="dashed">
                  <BsDownload />
                 <span className="ms-1"><a style={pdfa} href={pdfFree} target="_blank" rel="noreferrer"> Download</a></span>   
                  </Button>
             
                </Card>
               
                </Col>

            </Row>

            </Slide>
            <Slide Cascade direction="right">

            <Row>  
                <Col md={4} sm={12}>
              
                <Card title="" bordered={false} >
                    
                <p><img fluid  width="100%" height="100%"  src={close}/></p>
                
                <h4>Close to the Sky</h4>
                <p>Performance art project </p>
                <Button style={pdfbutton} type="dashed">
                  <BsDownload />
                 <span className="ms-1"><a style={pdfa} href={pdfClose} target="_blank" rel="noreferrer"> Download</a></span>   
                  </Button>
          
                </Card>
               
                </Col>

                <Col md={4} sm={12}>
              
                <Card title="" bordered={false} >
                
                <p><img fluid  width="100%" height="100%"  src={art}/></p>
                <h4>Art History cover </h4>
                <p>Artbook che racconta le cover piu iconiche della storia della musica </p>
                <Button style={pdfbutton} type="dashed">
                  <BsDownload />
                 <span className="ms-1"><a style={pdfa} href={pdfArt} target="_blank" rel="noreferrer"> Download</a></span>   
                  </Button>
             
                </Card>
               
                </Col>
                <Col md={4} sm={12}>
              
                <Card title="" bordered={false} >
             
                <p><img fluid  width="100%" height="100%"  src={franklin}/></p>
             
                <h4>Fanklin Jones</h4>
                <p>Direzione creativa per metodologia della progettazione  </p>
                <Button style={pdfbutton} type="dashed">
                  <BsDownload />
                 <span className="ms-1"><a style={pdfa} href={pdfFranklin} target="_blank" rel="noreferrer"> Download</a></span>   
                  </Button>
             
                </Card>
               
                </Col>

            </Row>
            </Slide>
        </Container>

        <hr></hr>

        <Container>
            <Row className="pb-5">
                <Col md={6} sm={12}><img fluid  width="100%" height="100%"  src={about}/></Col>
                <Col md={6} sm={12} className="mt-5">
              <div className="divdesc pt-5">
                <h3>ABOUT ME</h3>
                <p >Ho frequentato il corso di Fashion Styling & Communication allo IED di Milano e sono Owner di Fairmag, un magazine digitale... 
                   </p>
                   <Button  type="dashed" >
                   <Link to={"/AboutMe"}><b>Continua a leggere</b></Link>
                  </Button>              
                   </div>
                </Col>
                </Row>       
        </Container>

        <hr></hr>
        <Container>
            <Row className="pt-5 pb-5">
            <Col md={6} sm={12} className="d-sm-none" >
                <img fluid className="image_4"  width="100%" height="100%"  src={copertina}/>
              
                </Col>
                <Col md={6} sm={12} className="mt-5">
                  <div className="divdesc">
                <h1 className="d-none d-sm-block">FAIRMAG</h1>
                <p className="text-justify"> 
                <b> Fairmag</b> è un  <b>Magazine Digitale</b> che tratta le tematiche di Musica, Moda e Lifestyle con un taglio <b>Urban</b>, 
                  mirato al target della <b>Generazione Z</b>. Il nome nasce dalla connessione tra <b>FUOCO</b> (come suono) e <b>AIR</b>, l'aria,
                   che rimanda alle sneakers iconiche di Nike, come se fosse un Magazine che vuole raccontare il rapporto tra
                    la <b>Musica</b> e lo <b>Streetwear</b>, alla nascita i contenuti rappresentavano le collaborazioni tra Artisti e Designer,
                     con contenuti fotografici di live e campagne moda. Le radici sono quelle della cultura  <b>Hip-Hop</b> e <b>Rap</b>. 
                   
                </p>
                <p>  Lavoriamo a stretto contatto con Uffici Stampa di Artisti italiani e non.</p>
                   <Button  type="dashed" >
                   <a href= "https://www.instagram.com/fairmag_/" target='blank'><b><span className="me-3"><BsInstagram /></span>Instagram</b></a>
                  </Button>              
                   </div></Col>
                <Col md={6} sm={12} className="d-none d-sm-block">
                <img fluid className="img_4"  width="100%" height="100%"  src={copertina}/>
              
                </Col>
                </Row>       
        </Container>

        <hr></hr>

        <Container className="mb-3">
          <Row>
            <Col md={3} sm={6}>
            <img fluid className="img_4bis" width="100%" height="100%"  src={foto_5}/>
            </Col>
            <Col md={3} sm={6}>
            <img fluid className="img_4"  width="100%" height="100%"  src={foto_6}/>
            </Col>
            <Col md={3} sm={6}>
            <img fluid className="img_4"  width="100%" height="100%"  src={foto_7}/>
            </Col>
            <Col md={3} sm={6}>
            <img fluid className="img_4bis"   width="100%" height="100%"  src={foto_8}/>
            </Col>
          </Row>
        </Container>
      
        <Container>
          <Row className="pb-3">
            <Col>
            <a  href="https://www.instagram.com/lorenzonegriii/" style={iconsx}>
            <BsInstagram style={icon}/>
            </a>
            </Col>
            <Col>
            <a  href="https://www.linkedin.com/in/lorenzo-negri-a67599209/" style={icondx}>
            <GrLinkedin  style={icon} />
            </a>
            </Col>
          </Row>
        </Container>
        
        <Navbar bg="dark" fixed-bottom>
        <Container>
          <Navbar.Brand className="m-auto" style={footer_text} > 
            Riccardo Uccellini @ 2022 | Lorenzo Negri 
             </Navbar.Brand>
          </Container>
        </Navbar>   
      </div>
    );
  };
  
  export default Home;