
import './App.css';
import { BrowserRouter,Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Portfolio from './pages/portfolio';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.css'
import AboutMe from './pages/aboutme';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Home/>}> </Route>
      <Route exact path="/Portfolio" element={<Portfolio/>}> </Route>
      <Route exact path="/AboutMe" element={<AboutMe/>}> </Route>
    </Routes>  
  </BrowserRouter>
  );
}

export default App;
